// Our variables
@import "variables";

// import required partials
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

// TODO clean all this @henri :p
// import required partials
@import "~bootstrap/scss/root";
@import "~bootstrap/scss/reboot";
@import "~bootstrap/scss/type";
@import "~bootstrap/scss/images";
@import "~bootstrap/scss/code";
@import "~bootstrap/scss/grid";
@import "~bootstrap/scss/tables";
@import "~bootstrap/scss/forms";
@import "~bootstrap/scss/buttons";
@import "~bootstrap/scss/transitions";
// @import "~bootstrap/scss/dropdown";
// @import "~bootstrap/scss/button-group";
// @import "~bootstrap/scss/input-group";
// @import "~bootstrap/scss/custom-forms";
// @import "~bootstrap/scss/nav";
// @import "~bootstrap/scss/navbar";
// @import "~bootstrap/scss/card";
// @import "~bootstrap/scss/breadcrumb";
// @import "~bootstrap/scss/pagination";
// @import "~bootstrap/scss/badge";
// @import "~bootstrap/scss/jumbotron";
// @import "~bootstrap/scss/alert";
// @import "~bootstrap/scss/progress";
// @import "~bootstrap/scss/media";
// @import "~bootstrap/scss/list-group";
// @import "~bootstrap/scss/close";
// @import "~bootstrap/scss/toasts";
// @import "~bootstrap/scss/modal";
// @import "~bootstrap/scss/tooltip";
// @import "~bootstrap/scss/popover";
// @import "~bootstrap/scss/carousel";
// @import "~bootstrap/scss/spinners";
@import "~bootstrap/scss/utilities";
@import "~bootstrap/scss/print";

@import "~select2/dist/css/select2.min.css";

@import "fonts";
@import "mixins";

html,
body {
  height: 100%;
}

body {
  font-family: $font-family-regular;
  height: 100%;
  background: #ffffff;
}

// Header

header {
  display: flex;
  padding: 1rem 0;
  border-bottom: 3px solid $secondary-color;
  background-color: $primary-color;

  .logo-sud-ouest {
    width: 120px;
  }

  .choose-product {
    display: flex;

    label {
      font-size: rem(14px);
      margin-right: 0.5rem;
      text-align: center;
      color: #ffffff;
    }

    select {
      font-size: rem(14px);
      width: 100%;
    }
  }
}

// utils

a {
  color: $cuma-blue;

  &.btn-cumalink {
    font-size: rem(13px);
    font-weight: bold;

    line-height: rem(34px);

    display: flex;
    margin: 1rem 0;
    color: #484848;

    &:before {
      display: inline-flex;
      width: 34px;
      height: 34px;
      margin-right: 0.5rem;
      content: '';
      border-radius: 50%;
      background: $cuma-blue url('../img/icon-cumalink.svg') no-repeat center center;
    }

    &:hover,
    &:active {
      text-decoration: none;
      color: $cuma-blue;

      &:before {
        content: '';
        background: #5995a6 url('../img/icon-cumalink.svg') no-repeat center center;
      }
    }
  }

  &.btn-lien-alternative {
    font-size: rem(16px);
    font-weight: 600;
    display: flex;
    padding: 0.5rem 2rem 0.5rem 0.5rem;
    color: #ffffff;
    background-color: #5995a6;

    &:before {
      display: inline-flex;
      width: 21px;
      height: 21px;
      margin-right: 0.5rem;
      content: '';
      background: url('../img/icon-plus.svg') no-repeat center center;
    }

    &:hover,
    &:active {
      text-decoration: none;
      background-color: $cuma-blue;
    }
  }
}

// Main

.bg-image {
  display: block;
  min-height: 800px;
  padding: 2rem 0;
  background-position: center center;
  background-size: cover;
}

.home-title {
  font-family: $font-family-title;
  font-size: rem(42px);
  text-align: center;
  color: #ffffff;

  &.machine-name {
    font-size: rem(36px);
    text-align: left;
    color: $secondary-color;

    .subtitle {
      font-size: rem(26px);
      color: $cuma-green;
    }
  }
}

.subtitle {
  font-size: rem(26px);
  color: $cuma-green;
}

.mentions-legales, .data-orgin-manual {
  h3 {
    font-size: rem(26px);
  }
  h4 {
    font-size: rem(24px);
  }
}

main {
  .choose-product {
    font-family: $font-family-title;
    font-size: rem(12px);
    max-width: 450px;
    margin: 4rem auto;
    padding: 2rem;
    text-align: center;
    color: #ffffff;
    border: 1px solid #979797;
    border-radius: 8px;
    background: #0a4266;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.50);

    label {
      font-size: rem(14px);
      text-align: center;
    }

    select {
      width: 100%;
    }
  }

  p, ul, ol {
    &.nota {
      font-size: rem(12px);
    }
  }
}

.media {
  &.off-canvas {
    margin-left: -33%;
    @include media-breakpoint-down(lg) {
      margin-left: 0;
    }
  }
}

.table {
  &.table-short {
    width: auto
  }

  &.table-md-short {
    @include media-breakpoint-down(md) {
      width: auto
    }
  }

  thead {
    border-bottom: 4px solid #dee2e6;

    th {
      vertical-align: top;
      border: none;

      &.centered {
        text-align: center;
      }

      &.align-right {
        text-align: right;
      }
    }
  }

  th, td {
    font-size: rem(12px);
  }

  td {
    text-align: right;
    white-space: nowrap;

    &.cell-unit {
      font-weight: bold;
      text-align: left;
      color: $cuma-green;
    }

    &.centered {
      text-align: center;
    }
  }
}

// Accordion

.accordion-header {
  margin-bottom: 0.5rem;
  background-color: $primary-color;

  button {
    font-size: rem(16px);
    font-weight: bold;
    color: #ffffff;

    &:hover,
    &:active {
      text-decoration: none;
      color: #ffffff;
    }
  }
}

// Footer

footer {
  font-size: rem(16px);
  padding: 1rem 0 0 0;

  .logo-sud-ouest {
    width: 120px;
  }
}

.grey-footer {
  background-color: #D8D8D8;
}

.light-grey-footer {
  background-color: #eaeaea;
}

#partenaires-region {
  img {
    max-height: 75px;
  }
}

mark {
  background-color: #e7d058;
}
